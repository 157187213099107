import React from "react"
import PropTypes from "prop-types"
import SanityImage from "gatsby-plugin-sanity-image"
import Link from "components/Link"
import InvisibleButton from "components/buttons/InvisibleButton"
import theme from "styles/theme"

import { ReactComponent as PlusIcon } from "images/navigation/bluePlus.svg"
import { ReactComponent as Arrow } from "images/icons/arrow.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const columnWidth = 245

export const DropdownContent = ({
  alignment,
  columnCount,
  hasHighlight,
  className,
  children,
  ...rest
}) => {
  const noOfColumns = columnCount

  return (
    <div
      css={[
        {
          backgroundColor: theme.white,
          position: "absolute",
          top: 51,
          display: "none",
          width: Math.min(1440, noOfColumns * columnWidth),
          gridTemplateColumns: `repeat(${noOfColumns}, 1fr)`,
          paddingTop: 30,
          boxShadow: theme.cardShadow,
          borderBottom: `6px solid ${theme.midBlue}`,
          paddingBottom: hasHighlight ? 0 : 35,
        },
        alignment === "center" && {
          marginLeft: -columnWidth * 0.5 * columnCount,
          left: "50%",
        },
        alignment === "offsetLeft" && {
          marginLeft: -columnWidth * 0.75 * columnCount,
          left: "20%",
        },
        alignment === "justify" && {
          left: "calc(50vw - 720px)",
          marginTop: "var(--justifiedNavDropdownOffset, 65px)",
          [theme.xl]: {
            left: "auto",
            right: 0,
          },
          "@media(min-width: 1600px)": {
            left: "calc(50vw - 720px)",
            right: "auto",
          },
        },
      ]}
      className={className}
      {...rest}
    >
      {children}
    </div>
  )
}

export const DropdownColumn = ({ linkLists, onNavigate }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      padding: "0 24px",
      ":first-of-type": {
        marginLeft: 10,
      },
      "& + &": {
        borderLeft: `1px solid ${theme.lightGray}`,
      },
      "a + div": {
        marginTop: 30,
      },
    }}
  >
    {linkLists.map(({ title, icon, links, contacts }) => (
      <React.Fragment key={title}>
        <DropdownTitle title={title} icon={icon} />

        {links?.map(({ title, url }) => (
          <DropdownLink
            key={title}
            title={title}
            url={url}
            onClick={onNavigate}
          />
        ))}

        {contacts?.map(contact => (
          <DropdownContact key={contact.title} {...contact} />
        ))}
      </React.Fragment>
    ))}
  </div>
)

DropdownColumn.propTypes = {
  onNavigate: PropTypes.func,
  linkLists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        hotspot: PropTypes.object,
        crop: PropTypes.object,
        asset: PropTypes.object.isRequired,
      }).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          body: PropTypes.string.isRequired,
          type: PropTypes.oneOf(["emailContact", "phoneContact"]).isRequired,
          email: PropTypes.string,
        })
      ),
    })
  ).isRequired,
}

export const DropdownTitle = props =>
  props.collapsible ? (
    <CollapsibleDropdownTitle {...props} />
  ) : (
    <StandardDropdownTitle {...props} />
  )

export const CollapsibleDropdownTitle = ({
  icon,
  title,
  expanded = false,
  onClick,
  className,
}) => (
  <InvisibleButton
    css={[
      {
        width: "100%",
        userSelect: "none",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 10,
        transition: "border-bottom 300ms",
        borderBottom: `1px solid ${expanded ? theme.midBlue : theme.slate}`,
        [theme.navBreak]: {
          marginBottom: 6,
        },
      },
    ]}
    onClick={onClick}
    className={className}
  >
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: 32,
      }}
    >
      <SanityImage
        {...icon}
        css={{
          marginRight: 10,
          width: 22,
        }}
      />
      <span
        css={[
          theme.headerBold,
          {
            color: theme.midBlue,
          },
        ]}
      >
        {title}
      </span>
    </div>

    <PlusIcon
      css={{
        width: 11,
        transition: "transform 200ms",
        transform: `rotateZ(${expanded ? 45 : 0}deg)`,
      }}
      aria-hidden
    />
  </InvisibleButton>
)

CollapsibleDropdownTitle.propTypes = {
  icon: PropTypes.shape({
    hotspot: PropTypes.object,
    crop: PropTypes.object,
    asset: PropTypes.object.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export const StandardDropdownTitle = ({ icon, title, className }) => (
  <div
    css={[
      {
        width: "100%",
        userSelect: "none",
        [theme.navBreak]: {
          marginBottom: 6,
        },
      },
    ]}
    className={className}
  >
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: 32,
      }}
    >
      <SanityImage
        {...icon}
        css={{
          marginRight: 10,
          width: 22,
        }}
      />
      <span
        css={[
          theme.headerBold,
          {
            color: theme.midBlue,
          },
        ]}
      >
        {title}
      </span>
    </div>
  </div>
)

StandardDropdownTitle.propTypes = {
  icon: PropTypes.shape({
    hotspot: PropTypes.object,
    crop: PropTypes.object,
    asset: PropTypes.object.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export const DropdownLink = ({ title, url, className, onClick }) => (
  <Link
    css={[
      theme.headerDropdownLink,
      {
        color: theme.gray,
        marginTop: 19,
        alignSelf: "flex-start",
        borderBottom: "1px solid transparent",
        textDecoration: "none",
        outlineOffset: -2,
        ":hover": {
          color: theme.midBlue,
          borderBottom: `1px solid ${theme.midBlue}`,
          svg: {
            visibility: "visible",
          },
        },
        [theme.navBreak]: {
          display: "block",
          paddingBottom: 19,
          borderBottom: `1px solid ${theme.lightGray}`,
          alignSelf: "unset",
        },
      },
    ]}
    className={className}
    href={url}
    onClick={onClick}
  >
    {title}
    <Arrow
      css={{
        display: "inline-block",
        width: 12,
        marginLeft: 4,
        visibility: "hidden",
        path: { strokeWidth: 2 },
        [theme.navBreak]: {
          display: "none",
        },
      }}
    />
  </Link>
)

export const DropdownContact = ({ title, body, type, email }) => (
  <>
    <div
      css={[
        theme.headerDropdownLink,
        {
          marginTop: 19,
          color: theme.gray,
        },
      ]}
    >
      {title}
    </div>
    <div
      css={[
        theme.headerHighlight,
        {
          marginTop: 2,
          color: theme.gray,
          [theme.navBreak]: {
            paddingBottom: 19,
            borderBottom: `1px solid ${theme.lightGray}`,
          },
        },
      ]}
    >
      {type === "emailContact" ? (
        <OutboundLink
          href={`mailto:${email}`}
          target="_blank"
          rel="noopener noreferrer"
          css={{
            textDecoration: "underline",
            color: "inherit",
            ":hover": { color: theme.darkGray },
          }}
        >
          {body}
        </OutboundLink>
      ) : (
        body
      )}
    </div>
  </>
)

DropdownContent.propTypes = {
  alignment: PropTypes.oneOf(["center", "offsetLeft", "justify"]).isRequired,
  columnCount: PropTypes.number.isRequired,
  hasHighlight: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

DropdownTitle.propTypes = {
  icon: PropTypes.shape({
    hotspot: PropTypes.object,
    crop: PropTypes.object,
    asset: PropTypes.object.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
  collapsible: PropTypes.bool,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

DropdownLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

DropdownContact.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  email: PropTypes.string,
}
